<template>
  <div class="ellipsis cell-icon-wrapper">
    <img
      v-if="iconPath"
      class="cell-icon mr-2"
      :class="{
        'has-opacity-02': isIncognito || isUnregistered,
        'has-opacity-04': !isIncognito && !isUnregistered
      }"
      draggable="false"
      :src="iconPath"
      :alt="name"
    >
    <div
      class="ellipsis"
      :class="{
        'has-opacity-04': isIncognito || isUnregistered
      }"
    >
      {{ name }}
    </div>
  </div>
</template>

<script>
import {
  computed, defineComponent, watch,
} from 'vue';

import { useImageLoader } from '@/composables/useImageLoader';
import { i18n } from '@/plugins/localization';

import { useProps } from './useProps';

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { item, isEmptyRow } = useProps(props.params);
    const { t } = i18n.global;

    const isIncognito = computed(() => !props.params.data?.clientEmail && !props.params.data?.clientId);
    const isUnregistered = computed(() => props.params.data?.clientEmail && !props.params.data?.clientName && !props.params.data?.clientId);

    const iconPath = useImageLoader(isEmptyRow.value ? 'empty' : 'user-filled').path;
    watch(isIncognito, () => {
      if (!isEmptyRow.value) {
        let iconName = 'user-filled';
        if (isIncognito.value) {
          iconName = 'incognito';
        } else if (isUnregistered.value) {
          iconName = 'user-filled-clock';
        }

        const { path } = useImageLoader(iconName);
        if (isIncognito.value || isUnregistered.value) {
          iconPath.value = path.value;
        }
      }
    }, { immediate: true });

    const name = computed(() => {
      if (isEmptyRow.value) {
        return '';
      }
      if (isIncognito.value) {
        return t('common.incognito');
      }
      if (isUnregistered.value) {
        return t('status.UNREGISTERED');
      }

      return item.value;
    });

    return {
      name,
      iconPath,
      isEmptyRow,

      isIncognito,
      isUnregistered,
    };
  },
});
</script>

<style lang="scss">
@import './styles';
</style>
