<template>
  <div class="currency ellipsis">
    {{ number }}
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { currency } from '@/composables/useCurrency';
import { useSettings } from '@/composables/useSettings';
import { useAdmin } from '@/composables/useAdmin';
import { useToken } from '@/composables/useToken';

import { formatNumber } from '@/utils/numberFormatter';

import { useProps } from './useProps';
import { numberTypes } from '@/common/data';

export default defineComponent({
  setup(props) {
    const { item } = useProps(props.params);

    const sign = computed(() => currency.value.sign);

    const { isAdminRole } = useToken();
    const { numberFormat } = isAdminRole.value ? useAdmin() : useSettings();

    const value = computed(() => {
      return (item.value ? formatNumber({
        value: item.value,
        format: numberFormat.value,
        numberType: numberTypes.FLOAT2,
      }) : null)
    });

    const number = computed(() => (value.value ? `${sign.value}${value.value}` : '-'));

    return { number, sign };
  },
});
</script>
