<template>
  <div class="ellipsis">
    {{ item ? t(`paymentType.${item}`) : '-' }}
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useProps } from './useProps';

export default defineComponent({
  setup(props) {
    const { item } = useProps(props.params);

    return { item };
  },
});
</script>
