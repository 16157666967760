<template>
  <div class="ag-cell-label-container" @click="onClick">
    <div
      v-if="!isSelectRowColumnType"
      class="ag-header-cell-label"
      :class="{
        [fieldName]: fieldName,
        'default-cursor': !fieldName,
      }"
    >
      <span v-if="fieldName" class="ag-header-cell-text">{{ title }}</span>
      <svg
        v-if="fieldName"
        class="sort-icon"
        draggable="false"
        :data-src="path"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount } from 'vue';

import { i18n } from '@/plugins/localization';
import { emitter } from '@/composables/useBus';
import { useImageLoader } from '@/composables/useImageLoader';

import { TABLE_TYPES } from '@/common/tableTypes';

export default defineComponent({
  setup(props) {
    const fieldName = computed(() => props.params.column.colDef.field);
    const moduleName = computed(() => props.params.column.colDef.headerComponentParams.moduleName);
    const isSelectRowColumnType = computed(() => props.params.column.colDef.headerComponentParams.type === TABLE_TYPES.ROW_SELECTION);

    const { t } = i18n.global;
    const title = computed(() => props.params.column.colDef.headerComponentParams?.title || t(`${moduleName.value}.table.columns.${fieldName.value}`));

    const { path } = useImageLoader('arrow-dropdown');

    const onClick = () => {
      if (fieldName.value && !isSelectRowColumnType.value) {
        emitter.emit('column-sort-by', fieldName.value);
      }
    };

    emitter.on(`sort-by-${fieldName.value}`, (orderBy) => {
      if (fieldName.value) {
        const selector = document.querySelector(`.ag-header-cell-label.${fieldName.value}`);
        if (orderBy === 'asc') {
          selector.classList.remove('sort-desc');
          selector.classList.add('sort-asc');
        } else if (orderBy === 'desc') {
          selector.classList.remove('sort-asc');
          selector.classList.add('sort-desc');
        } else {
          selector.classList.remove('sort-asc');
          selector.classList.remove('sort-desc');
        }
      }
    });

    onBeforeUnmount(() => {
      emitter.off(`sort-by-${fieldName.value}`);
    });

    return {
      fieldName,
      isSelectRowColumnType,

      title,
      path,

      onClick,
    };
  },
});
</script>

<style lang="scss">
@import './Cells/styles';
</style>
