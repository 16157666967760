<template>
  <div v-if="item" class="ellipsis">
    {{ text }}
  </div>
  <div v-else-if="isEmptyRow" />
  <div v-else>-</div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { fullCoinsArray } from '@/common/data';

import { useProps } from './useProps';

export default defineComponent({
  setup(props) {
    const { item, row, isEmptyRow } = useProps(props.params);

    const selectedCurrency = computed(() => fullCoinsArray.find(coin => coin.value === row.value.exchangeCurrency))
    const textCurrency = computed(() => selectedCurrency.value?.isPrimary ? selectedCurrency.value?.value : `${selectedCurrency.value?.name} (${selectedCurrency.value?.family})`)
    
    const text = computed(() => (row.value.exchangeCurrency ? `${ item.value } ${ textCurrency.value }` : '-'));

    return { item, text, isEmptyRow };
  },
});
</script>
