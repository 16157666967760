<script>
import { defineComponent, h } from 'vue';

import { useProps } from '../useProps';

export default defineComponent({
  setup(props) {
    const { item, row, column } = useProps(props.params);

    const {
      renderFunction,
      callback = () => {},
      opts = {},
      hasParentBlock = true,
    } = column.value.headerComponentParams.render;

    return () => (hasParentBlock ? h(
      'div', {
        class: 'ellipsis',
        ...opts,
        onClick: () => { callback({ item, row, column }); },
      },
      renderFunction({ item, row, column }),
    ) : renderFunction({ item, row, column }));
  },

});
</script>
