<template>
  <div class="date ellipsis">
    {{ formattedDate }}
  </div>
</template>

<script>
import { useDate } from './useDate';

export default {
  name: 'Date',
  inheritAttrs: true,
  setup(props) {
    const { formattedDate } = useDate(props.params);

    return { formattedDate };
  },
};
</script>
