<template>
  <div class="ellipsis">
    <TooltipCopy v-if="item" is-icon-hidden is-table-inlined :text="item">
      <span>{{ item }}</span>
    </TooltipCopy>
    <span v-else-if="isEmptyRow" />
    <span v-else> - </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';
import { useProps } from './useProps';

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    TooltipCopy,
  },
  setup(props) {
    const { item, isEmptyRow } = useProps(props.params);

    return {
      item,
      isEmptyRow,
    };
  },
});
</script>
