<template>
  <div class="date ellipsis">
    {{ formattedDate }} 
  </div>
</template>

<script>
import { useProfile } from '@/composables/useProfile';

import { useDate } from './useDate';

export default {
  name: 'Date',
  inheritAttrs: true,
  setup(props) {
    const { timeFormat } = useProfile();
    const { formattedDate } = useDate(props.params, { timeFormat: timeFormat.value });

    return { formattedDate };
  },
};
</script>
