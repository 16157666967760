<template>
  <div class="ellipsis">
    {{ status }}
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { makeFirstLetterUppercased } from '@/utils/functions';

import { useProps } from './useProps';

export default defineComponent({
  setup(props) {
    const { item } = useProps(props.params);

    const status = computed(() => {
      if (!item.value) {
        return '-'
      }
      if (item.value === 'IO') {
        return item.value;
      }
      
      return makeFirstLetterUppercased(item.value)
    })
    
    return { item, status };
  },
});
</script>
