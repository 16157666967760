import { computed } from 'vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useProps } from './useProps';

import { useAdmin } from '@/composables/useAdmin';
import { useSettings } from '@/composables/useSettings';
import { useToken } from '@/composables/useToken';

export const useDate = (propsParams, opts = {}) => {
  const { item, isEmptyRow } = useProps(propsParams);
  const format = computed(() => propsParams.dateFormat.value);

  const { isAdminRole } = useToken();

  const { dateFormat, timezoneCode } = isAdminRole.value ? useAdmin() : useSettings();
  const currentDateFormat = computed(() => (format.value ? format.value : dateFormat.value));
  const currentDateTimeFormat = computed(() => (opts.timeFormat
    ? `${currentDateFormat.value} (${opts.timeFormat})`
    : currentDateFormat.value));

  const dateFormatter = (date) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    try {
      return dayjs(date).tz(timezoneCode.value);
    } catch (error) {
      return dayjs(date);
    }
  };

  const formattedDate = computed(() => {
    if (isEmptyRow.value) {
      return '';
    }
    return item.value ? dateFormatter(item.value).format(currentDateTimeFormat.value) : '-';
  });

  return { formattedDate };
};
