<template>
  <div
    style="position: relative"
    class="table-grid-wrapper"
    :class="{
      'has-no-data': data.length === 0,
      'is-grid-ready': isGridReady,
    }"
  >
    <LinearProgress :is-loading="isLoading" />
    <AgGridVue
      class="ag-grid-vue-wrapper"
      :row-data="rowData"
      :get-row-id="getRowId"
      :style="tableStyles"
      :column-defs="agColumns"
      :header-height="36"
      :row-height="40"
      :suppress-horizontal-scroll="true"
      :no-rows-overlay-component="'NoData'"
      :no-rows-overlay-component-params="{ shouldParamsBeReseted }"
      :dom-layout="heightAuto ? 'autoHeight' : 'normal'"
      :tab-to-next-header="() => {}"
      :tab-to-next-cell="() => {}"
      :suppress-auto-size="true"
      col-resize-default="shift"
      row-selection="multiple"
      :row-multi-select-with-click="true"
      :suppress-header-keyboard-event="() => {}"
      :suppress-row-click-selection="true"
      :suppress-column-move-animation="true"
      :suppress-header-focus="true"
      :animate-rows="false"

      @grid-ready="onGridReady"
      @cell-clicked="onCellClicked"
      @column-resized="onColumnResized"
      @selection-changed="onRowSelectionChanged"
    />
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import { AgGridVue } from 'ag-grid-vue3';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';

import LinearProgress from '@/components/Progress/LinearProgress.vue';

import { TABLE_TYPES } from '@/common/tableTypes';
import { updateTableColumn } from '@/store';
import { emitter } from '@/composables/useBus';

import { components, getCellRenderer, useAgGrid } from './useAgGrid';
import { useCustomTabNavigation } from './useCustomTabNavigation';

export default defineComponent({
  components: {
    AgGridVue,
    LinearProgress,
    ...components,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      default: '',
    },
    tableStorageName: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    sortedParams: {
      type: Object,
      default: () => {},
    },
    currentFilters: {
      type: Object,
      default: () => {},
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
    heightAuto: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cell-click', 'sort-by', 'reset-filters', 'select-rows', 'grid-ready'],
  setup(props, { emit, expose }) {
    const {
      getWidth,
      isColumnVisible,
      dateFormat,
    } = useAgGrid(props.tableStorageName || props.moduleName);

    const simpleColumns = props.columns
      .map((column) => ({
        field: column.name,
        resizable: false,
        suppressMovable: true,
        minWidth: 60,
        cellRenderer: getCellRenderer(column.type),
        enableCellChangeFlash: true,
      }));

    const prepareColumns = (rawColumns) => rawColumns
      .map((column) => ({
        field: column.name,
        hide: !isColumnVisible(column.name),
        resizable: column.resizable !== false,
        suppressMovable: true,
        minWidth: column.type === TABLE_TYPES.ROW_SELECTION ? 40 : (column.minWidth || 60),
        maxWidth: column.type === TABLE_TYPES.ROW_SELECTION ? 40 : undefined,
        // flex: column.flex,
        initialWidth: getWidth(column),
        cellRenderer: getCellRenderer(column.type),
        cellRendererParams: {
          dateFormat,
        },
        headerComponent: 'HeaderCell',
        headerComponentParams: {
          moduleName: props.tableStorageName || props.moduleName,
          render: column.render,
          type: column.type,
          title: column.title, // very rare. need for extra exceptions
        },
        enableCellChangeFlash: true,
        headerCheckboxSelection: column.headerCheckboxSelection,
        checkboxSelection: column.checkboxSelection,
      }));

    const agColumns = props.isSimple ? simpleColumns : prepareColumns(props.columns);

    const updateColumnHiddenState = ({ columnName, hide }) => {
      gridColumnApi.value.applyColumnState({
        state: [{ colId: columnName, hide }],
      });
    };

    const setNewColumns = (newStatus) => {
      const otherColumns = ['createdAt', 'deletedAt', 'plan', 'cycle', 'status'];
      if (newStatus === 'PRUNED') {
        otherColumns.forEach((columnName) => {
          updateColumnHiddenState({ columnName, hide: true });
        });
      } else {
        otherColumns.forEach((columnName) => {
          updateColumnHiddenState({ columnName, hide: !isColumnVisible(columnName) });
        });
      }

      updateColumnHiddenState({ columnName: 'prunedOn', hide: newStatus !== 'PRUNED' });

      gridReadyPostProcess();
      gridReadyPostProcess();
    };

    expose({ setNewColumns });

    const tableStyles = computed(() => ({
      height: `calc(100vh - ${props.noPagination ? '264px' : '290px'})`,
    }));

    const onCellClicked = (cell) => {
      if (!cell.data.isEmptyRow) {
        emit('cell-click', cell.data);
      }
    };

    const onColumnResized = (table) => {
      table.columns.forEach((column) => {
        updateTableColumn({
          moduleName: props.tableStorageName || props.moduleName,
          columnName: column.colDef.field,
          field: 'width',
          value: column.actualWidth,
        });
      });
    };

    const gridApi = ref(null);
    const gridColumnApi = ref(null);

    const fitColumns = () => {
      gridApi.value.sizeColumnsToFit();
    };

    const gridReadyPostProcess = () => {
      fitColumns();

      const lastColumn = gridApi.value.focusService.eGridDiv.querySelector(`.ag-header-cell[aria-colindex="${agColumns.length}"]`);

      if (lastColumn) {
        lastColumn.classList.add('is-last-col');
      }
    };
    const isGridReady = ref(false);
    const onGridReady = (params) => {
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;
      gridReadyPostProcess();

      emit('grid-ready');
      isGridReady.value = true;
    };

    emitter.on('toggle-column', (col) => {
      const { columnName, isShow } = col;
      updateColumnHiddenState({ columnName, hide: !isShow });
      fitColumns();
    });

    emitter.on('column-sort-by', (columnName) => {
      emit('sort-by', columnName);
    });

    emitter.on('no-data-reset-filters', () => {
      emit('reset-filters');
    });

    onBeforeUnmount(() => {
      emitter.off('column-sort-by');
      emitter.off('toggle-column');
      emitter.off('no-data-reset-filters');
      window.removeEventListener('resize', fitColumns);
    });

    const lastSortBy = ref('');
    watch(computed(() => props.sortedParams), () => {
      const { sortBy, orderBy } = props.sortedParams;
      if (sortBy !== lastSortBy.value) {
        emitter.emit(`sort-by-${lastSortBy.value}`, null);
      }
      if (sortBy) {
        lastSortBy.value = sortBy;
      }

      emitter.emit(`sort-by-${lastSortBy.value}`, orderBy?.toLowerCase());
    });

    const shouldParamsBeReseted = ref(false);
    watch(computed(() => props.currentFilters), () => {
      if (props.currentFilters?.length) {
        const shouldReset = Boolean(props.currentFilters.length && props.data.length === 0);
        shouldParamsBeReseted.value = shouldReset;
      } else {
        shouldParamsBeReseted.value = false;
      }

      setTimeout(() => { gridApi.value?.showNoRowsOverlay(); }, 0);

      if (props.data.length) {
        setTimeout(() => { gridApi.value?.hideOverlay(); }, 0);
      }
    }, { immediate: true });

    const { customTabNavigation } = useCustomTabNavigation(gridApi, {
      data: computed(() => props.data),
      emit,
    });

    // resolved the https://linear.app/frl/issue/SWI-2969#comment-9f3375bd
    const rowData = computed(() => props.data);
    onMounted(() => {
      window.addEventListener('resize', fitColumns);

      customTabNavigation();
    });

    const getRowId = (params) => params.data?.uuid || params.data?.id;

    const onRowSelectionChanged = () => {
      const selectedRows = gridApi.value.getSelectedRows().map(({ id }) => ({ id }));
      emit('select-rows', selectedRows);
    };

    return {
      agColumns,
      tableStyles,
      rowData,
      shouldParamsBeReseted,

      isGridReady,

      onCellClicked,
      onColumnResized,
      onGridReady,
      onRowSelectionChanged,

      getRowId,
    };
  },
});
</script>

<style scoped lang="scss">
@import './styles/core.scss';
@import './styles/header.scss';
@import './styles/row.scss';
@import './styles/row-selection.scss';
</style>
