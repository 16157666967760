<script>
import { defineComponent, h } from 'vue';

import { fullCoinsArray } from '@/common/data';
import { useImageLoader } from '@/composables/useImageLoader';

import { useProps } from './useProps';

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { item } = useProps(props.params);

    const selectedCoin = fullCoinsArray.find(coin => coin.value === item.value)

    if (!selectedCoin || !item.value) {
      return () => h('div', { class: 'd-flex align-items-center' }, '-');
    }
    
    const iconName = selectedCoin.icon;
    const familyIcon = selectedCoin.familyIcon;
    const coinName = (() => {
      if (item.value === 'ETH_ETH') {
        return selectedCoin.shortValue;
      }
      return selectedCoin.isPrimary ? selectedCoin.value : selectedCoin.name
    })()

    const { path } = useImageLoader(iconName);
    const { path: familyIconPath } = useImageLoader(familyIcon);

    const icon = [
      h('div', { style: 'position: relative; flex: 1 0 auto;' }, [
        h('img', { class: 'cell-icon', draggable: false, src: path.value,  alt: item.value }),
        !selectedCoin.isPrimary ? h('img', {
          class: 'cell-icon-family',
          draggable: false,
          src: familyIconPath.value,
        }) : '',
      ]),
      h('div', { class: 'ellipsis icon-text', style: 'margin-top: 1px;' }, coinName),
    ];

    return () => h('div', { class: 'd-flex align-items-center', style: 'gap: 10px;' }, icon);
  },
});
</script>

<style lang="scss">
@import './styles';
</style>
