<template>
  <div class="ellipsis">
    <span v-if="item">{{ item }}</span>
    <span v-else-if="isEmptyRow" />
    <span v-else> - </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useProps } from './useProps';

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { item, isEmptyRow } = useProps(props.params);

    return {
      item,
      isEmptyRow,
    };
  },
});
</script>
